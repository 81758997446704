.error {
  width: 100vw;
  height: 50vh;

  display: grid;
  place-content: center;
}

.error > span {
  color: black;
  font-size: 1.3rem;
}

.link {
  color: black;
  font-size: 1.3rem;
  text-decoration: underline;
}
