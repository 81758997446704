.prices {
  /* border: 1px solid red; */
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: start;
  align-items: center;
}

span {
  font-size: var(--font-size-1);
  /* font-weight: 900; */
  color: rgba(23, 30, 52, 0.88);
}

.originalPrice {
  font-size: var(--font-size-1);
  text-decoration: line-through;
}

.finalPrice {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-5);
}

.points {
  display: flex;
  gap: 5px;
  align-items: center;
}

.points > p {
  font-size: var(--font-size-2);
  color: darkorange;
}

.star {
  color: darkorange;
}

.priceWithTaxes {
  display: flex;
  gap: 10px;
  align-items: baseline;
  /* border: 1px solid red; */
}
