.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
    overflow-y: hidden;
}

.menu {
    position: fixed;
    background-color: black;
    padding: 1rem;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
    z-index: 30;
    height: 100vh;
    /* width: 60vw;
    max-width: 350px; */
    width: min(60vw, 350px);
    animation: slide-right 500ms ease-out forwards;

}

.container {
    position: relative;
    display: flex;
    /* width: 100%;
    border: 1px solid orange; */
}

.content {
    /* width: 100%; */
    height: 90vh;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: var(--font-size-3);
    font-size: 30px;
    /* border: 2px dotted red; */
}

.close {
    position: absolute;
    right: 0;
    /* padding: 5px; */
    /* border: 1px solid blue; */
    cursor: pointer;
}

.close:hover {
    transform: scale(1.1);
}

.logout {
    /* color: white; */

    position: absolute;
    bottom: 0px;
    left: 20px;

    /* border: 1px solid red; */

    display: flex;
    gap: 10px;

    cursor: pointer;
}

.logout > p {
    font-size: 25px;

    color: white;
}

.icon {
    color: white;
}

/* a.active {
    color: red
} */

@keyframes slide-right {
    from {
        opacity: 0;
        transform: translatex(-3rem);
    }
    to {
        opacity: 1;
        transform: translatex(0);
    }
}