.newUser {
  padding: 10px 0;
}

.buttons {
  display: flex;
  gap: 10px;
  align-items: center;

  padding: 15px 0;

  /* border: 1px solid red; */
}
