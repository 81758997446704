.isLoading {

    display: grid;
    place-content: center;

    /* border: 1px solid red; */
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* border: 1px dotted green; */
}

.selects {
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* border: dotted 1px red; */
}

.buttons {
    display: flex;
    gap: 10px;
    justify-content: end;

    /* border: 1px dotted black; */
}

.btn {
    flex: 1;

    /* border: 1px solid red; */
}

.btnTooltip {
    width: 100%;
}