.container {
  background-color: #000000;
  color: white;

  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;

  padding: 5px 10px;

  position: sticky;
  top: 0;
  z-index: 10;
}

.right {
  /* border: 1px solid red; */
  /* width: 35%; */
  /* max-width: 120px; */

  display: flex;
  /* flex-wrap: wrap-reverse; */
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.imgLogo {
  max-width: 120px;

  /* border: 1px solid red; */
}

.links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 7px;
}

.cart {
  /* position: relative; */

  font-size: 2.2rem;

  cursor: pointer;
}

.cart > p {
  display: inline;
  position: absolute;
  right: 5px;
}

.full {
  color: darkorange;
}

.empty {
  color: white;
}

/* OLD */
.nav {
  background-color: #000000;
  /* padding: 10px 0; */
  color: white;
}

.nav__links {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.nav__links__link {
  height: 100%;
  padding: 30px 0;
}

/* .nav__links__link:hover {
    color: yellow;
    background-color: red;
} */

.link {
  color: #fff;
}

.link:hover {
  color: #00f;
}

.left {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
}

.left:hover {
  transform: scale(1.1);
}

.left p {
  font-size: var(--font-size-1);
}
