.container {
  padding: 0 10px;

  margin: 10px auto;
  margin-bottom: 10px;

  width: min(var(--width-container), var(--max-width-container));

  /* border: 1px solid blue; */
}

.main {
  /* display: grid;
  place-content: center; */

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 25vh;

  background-image: url("../../../public/new-quote.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* border: 1px solid red; */
}

.cta {
  display: grid;
  place-content: center;

  padding-top: 40px;
  padding-bottom: 40px;
}

.magazine {
  display: flex;
  gap: 15px;

  /* width: min(90%, 350px); */

  /* border: 1px solid red; */
}

.option {
  flex: 1;
  padding: 30px;
  text-align: center;
  color: white;
  font-weight: 800;
  border-radius: var(--radius-2);
  cursor: pointer;
  background-color: darkorange;
  border: 1px solid darkorange;
}

.option:hover {
  background-color: white;
  /* border: 1px solid darkorange; */
  color: darkorange;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 5px;

  /* border: 1px solid red; */
}

.section {
  padding: 3px;

  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 10px;
}

.content {
  flex: 2;
  /* border: 1px solid green; */
}

.icon {
  font-size: 2.7rem;
  color: darkorange;

  flex: 1;
}

.points {
  display: flex;
  gap: 10px;
  align-items: center;

  /* border: 1px solid green; */
}

.infoIcon {
  cursor: pointer;
}
