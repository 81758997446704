table {
  width: 100%;
  border-spacing: 5px;
}

tr {
  text-align: left;
}

.number {
  text-align: right;
}

td {
  font-size: 1.1rem;
}

.remove {
  color: red;

  /* position: absolute;
  right: 10px; */

  cursor: pointer;
}

.remove:hover {
  color: white;
  background-color: red;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .number {
    text-align: left;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  th,
  td {
    vertical-align: middle;
    padding: 10px;
    text-align: left;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    /* margin: 0 0 1rem 0; */
    border-top: solid var(--border-size-1) black;
    border-bottom: solid var(--border-size-1) black;
  }

  tr:nth-child(odd) {
    /* background: #ccc; */
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    /* top: 0; */
    left: 6px;
    width: 45%;
    padding-right: 10px;
    /* white-space: nowrap; */
  }

  td:nth-of-type(1):before {
    content: "Nombre del producto";
    font-weight: bold;
  }
  td:nth-of-type(2):before {
    content: "Material";
    font-weight: bold;
  }
  td:nth-of-type(3):before {
    content: "Precio unitario";
    font-weight: bold;
  }
  td:nth-of-type(4):before {
    content: "Unidades";
    font-weight: bold;
  }
  td:nth-of-type(5):before {
    content: "Total";
    font-weight: bold;
  }
}
