.container {
  margin: 10px auto;
  width: min(var(--width-container), var(--max-width-container));

  /* border: 1px solid red; */
}

.gallery {
  /* display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;
      gap: var(--gap-small);
  
      width: 100%;
  
      padding: var(--gap-small); */

  display: grid;
  grid-auto-rows: auto;
  gap: 10px;
  grid-auto-flow: row dense;
  align-items: center;

  /* background-color: greenyellow; */
}

.gallery img {
  /* max-width: 200px; */
  /* max-height: 250px; */
  cursor: pointer;
  border-radius: var(--radius-1);
  box-shadow: var(--shadow-3);
}

.loading {
  width: 100%;
  height: 20vh;

  /* border: 1px solid red; */

  display: grid;
  place-content: center;
}

/* Tablet */
@media (min-width: 481px) and (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Pantallas pequelas */
@media (min-width: 769px) and (max-width: 1024px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Escritorio */
@media (min-width: 1025px) and (max-width: 1200px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* TV */
@media (min-width: 1201px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}
