.preview {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

/* .preview > h5 {
    flex: 1;
} */

.previewIcon {
  display: flex;
  gap: 5px;
  align-items: center;

  flex: 2;

  /* border: 1px dotted red; */
}

.content > h4 {
  margin: 0;
  padding: 5px;

  /* color: red; */
}

/* .content {
  margin: 0;
  padding: 0;
  border: 1px dotted green;
} */

.icon {
  font-size: 1.6rem;
}

.light {
  font-weight: 600;
}

.iconSaved {
  color: darkorange;
}

.iconProcessed {
  color: green;
}

.previewDetails {
  flex: 2;

  /* border: 1px dotted red; */
}

.rightIcon {
  flex: 1;
}

@media (min-width: 1400px) {
  .preview {
    padding: 0;
  }
}
