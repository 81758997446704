.errorField {
    color: red;
  }
  
  .formBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  
    /* border: 1px solid green; */
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 25px;
  
    /* border: 1px solid red; */
  }
  
  .label {
    display: block;
  }
  
  .input {
    width: min(90%, 350px);
    margin: 0 auto;
  
    display: flex;
    flex-direction: column;
    gap: 5px;
  
    /* border: 1px dotted red; */
  }
  
  .input > input {
    /* width: min(100%, 350px); */
  
    /* flex: 1; */
  
    /* border: 1px solid red; */
    width: 100%;
    height: 1.4rem;
    border-radius: var(--radius-3);
    padding: 2px;
    /* display: block; */
  }
  
  .buttons {
    width: min(90%, 350px);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  
    /* border: 1px solid red; */
  }
  
  .btn {
    flex: 1;
    /* width: min(100%, 350px);
    margin: 0 auto; */
  }
  