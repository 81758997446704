.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 90vw;
  max-width: var(--max-width-container);
  
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 10px;

  /* border: 1px solid red; */
}

.header {
  /* border: 1px solid red; */

  margin-bottom: 10px;
}

.quotes {

  display: flex;
  flex-direction: column;
  gap: 25px;

  /* border: 1px solid red; */
}

.newQuote {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 50px;

  cursor: pointer;

  /* box-shadow: var(--shadow-2);
  border-radius: var(--radius-2); */

  /* background-color: white; */

  /* background-color: black;
  border: 8px solid var(--mv-yellow); */
  /* color: darkorange;
  font-weight: bold;
  padding: 12px 19px;
  border-radius: var(--radius-4); */

  cursor: pointer;
}

/* .newQuote:hover {
  box-shadow: var(--shadow-4);
} */

.newText {
  font-size: var(--font-size-3);
}

.up {
  position: fixed;
  z-index: 50;
  bottom: 10px;
  right: 5px;

  cursor: pointer;
}

@media (min-width: 1000px) {
  .up {
    right: 200px;
  }
}