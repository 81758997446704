.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: min(90vw, var(--max-width-container));

  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 10px;

  /* border: 1px solid red; */
}


.tabs {
    display: flex;
    gap: 10px;

    /* border: 1px dotted red; */
}

.tab {
    flex: 1;
    text-align: center;
    padding: 20px 0;
    cursor: pointer;
    background-color: var(--gray-3);
}

.active {
    background-color: var(--gray-7);
    color: white;
    font-weight: 600;
}
