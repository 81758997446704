.product {
  padding: 10px 10px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;

  /* border: 1px solid red; */
}

.productName {
  font-size: var(--font-size-4);
  /* font-weight: 500; */
}

.product img {
  /* border: 1px solid blue; */
}

.productProps {
  display: flex;
  flex-direction: column;
  gap: 10px;

  flex: 1;

  /* border: 1px dotted black; */
}

.buttons {
  /* border: 1px solid blue; */
}

.selects {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: cadetblue; */
}

.cart {
  /* border: 1px solid white; */
  width: 20%;
  padding: 7px 0;
  cursor: pointer;

  /* display: flex;
  justify-content: center; */

  font-size: 1.5rem;

  border-radius: 5px;
}

.cart > input {
}

.active {
  color: var(--mv-yellow);
}

/* .active:hover {
  background-color: var(--mv-yellow);
  color: white;
} */

.disabled {
  color: gray;
}

.btn {
  /* margin: 15px auto; */

  /* background-color: var(--mv-yellow);
  border: 1px solid var(--mv-yellow); */

  padding: 7px 19px;
  border-radius: var(--radius-2);

  cursor: pointer;

  font-size: var(--font-size-1);

  /* width: 1000px; */

  /* display: flex;
  justify-content: center; */
}

.btnDisabled {
  /* margin: 15px auto; */

  background-color: var(--gray-2);
  border: 1px solid var(--gray-2);

  padding: 7px 19px;
  border-radius: var(--radius-2);

  cursor: pointer;

  /* display: flex;
  justify-content: center; */
}

.notFinalPrice {
  color: red;
  animation: fadein 1s;
}

.description {
  font-size: var(--font-size-1);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 769px) {
  .product {
    flex-direction: row;

    /* border: 1px solid red; */
  }

  .productImage {
    flex: 2;
  }

  .productProps {
    flex: 4;
  }
}
