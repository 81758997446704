.container {
  width: min(var(--width-container), var(--max-width-container));
  display: flex;
  flex-direction: column;
  /* gap: 5px; */

  /* border: 1px solid blue; */
}

/* .swiper {
  border: 11px solid red;
} */

/* .swiperContainer {
  width: 100%;

  display: flex;
  align-items: center;

  border: 1px solid red;
} */

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiperSlide {
  height: auto;
}

.swiperSlide > img {
  vertical-align: middle;
  border: 1px solid red;
  width: 95vw;
}

.swiperWrapper {
  align-items: center;
}

.fullScreenBox {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 20;

  background-color: black;
  opacity: 0.7;
}

.fullScreenBox img {
  z-index: 25;
  opacity: 1;
}