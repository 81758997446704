.button {
  margin: 10px 0;
}

.operations {
  display: flex;
  align-items: center;
  gap: 20px;

  /* border: 1px solid red; */
}

.selector {
  display: grid;
  grid-template-columns: repeat(auto-fill, 50px);
  gap: 5px;

  /* display: flex;
    flex-wrap: wrap;
    gap: 5px; */
}

.sample {
  width: 15px;
  height: 15;

  border: 1px solid black;

  /* background-color: black; */
}

.black {
  background-color: black;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.blue {
  background-color: blue;
}

.white {
  background-color: white;
}

.orange {
  background-color: orange;
}

.gold {
  background-color: gold;
}

.brush {
  background-image: -webkit-repeating-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0) 0%,
      hsla(0, 0%, 100%, 0) 6%,
      hsla(0, 0%, 100%, 0.1) 7.5%
    ),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 0%, 0) 0%, hsla(
            0,
            0%,
            0%,
            0
          )
          4%, hsla(0, 0%, 0%, 0.03) 4.5%),
    -webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(
            0,
            0%,
            100%,
            0
          )
          1.2%, hsla(0, 0%, 100%, 0.15) 2.2%),
    linear-gradient(
      180deg,
      hsl(0, 0%, 78%) 0%,
      hsl(0, 0%, 90%) 47%,
      hsl(0, 0%, 78%) 53%,
      hsl(0, 0%, 70%) 100%
    );
}

.carbon {
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
    linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
    linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
    linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
    linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
    linear-gradient(
      #1d1d1d 25%,
      #1a1a1a 25%,
      #1a1a1a 50%,
      transparent 50%,
      transparent 75%,
      #242424 75%,
      #242424
    );
  background-color: #131313;
  background-size: 20px 20px;
}
