.box {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 10px;
    border: 1px solid var(--gray-2);
    border-radius: var(--radius-2);

    background-color: white;

    /* border: 1px solid red; */

    /* margin: 10px 0; */
}

.icon {
    color: var(--gray-6);
}

.input {
    border: none;
    outline: none;
    width: 100%;
    /* padding: 30px 0; */
    height: 30px;
}