.units {
    display: flex;
    align-items: center;
    gap: 5px;
}

.units > p {
    font-size: var(--font-size-1);
}

.units > input {
    width: 30px;
    height: 30px;
}