.saved {
    background-color: var(--mv-yellow);
    width: min-content;
    padding: 4px 8px;
    border-radius: 38%;
}

.processed {
    background-color: var(--green-4);
    width: min-content;
    padding: 4px 8px;
    border-radius: 38%;
}