.products {
  display: flex;
  flex-direction: column;
  gap: var(--size-5);
}

.card {
  box-shadow: var(--shadow-6);
  /* border: 1px solid red; */
}
