.companies {
  display: flex;
  flex-direction: column;

  /* border: 1px solid red; */
}

.company {
  display: flex;
  gap: 10px;
}

.column {
  flex: 1;
}

.header {
  font-size: 1.3rem;
  font-weight: 800;
  padding: 10px 0;
}

.row {
  padding: 5px 0;
  cursor: pointer;
}

.row:hover {
  background-color: var(--gray-3);
}

.buttons {
  display: flex;
  gap: 10px;
  align-items: center;

  padding: 15px 0;

  /* border: 1px solid red; */
}
