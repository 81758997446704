.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 90vw;
  max-width: var(--max-width-container);

  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 10px;

  /* border: 1px solid red; */
}

.header {
  height: 0px;
  margin-bottom: 0px;
}

.orders {
  display: flex;
  flex-direction: column;
  gap: 25px;

  /* border: 1px solid red; */
}
