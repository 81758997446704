.container {
  display: flex;
  gap: 15px;
  align-items: center;

  /* border: 1px solid red; */
}

.pointer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pointer > p {
  font-size: var(--font-size-2);
  color: darkorange;
}

.star {
  color: darkorange;
  font-size: var(--font-size-2);

}
