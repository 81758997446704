.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 90vw;
  max-width: var(--max-width-container);

  padding: 0 10px;
  padding-bottom: 25px;
  margin: 0 auto;
  margin-bottom: 25px;

  /* border: 1px solid red; */
}

.header {
  /* height: min(10vh, 20%); */
  margin-bottom: 0px;

  /* border: 1px solid red; */
}

.filter {
  padding: 30px 0;
}

.filter_input {
  border: 1px solid red;
}

.filterIcon {
  display: flex;
  justify-content: baseline;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.filterData {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.filterText {
  display: flex;
  gap: 5px;
  /* border: 3px solid white; */
  border-radius: 25px;
  height: 15px;
  padding: 4px 6px;
  align-items: center;
  cursor: pointer;
  background-color: var(--green-3);
  color: var(--green-9);
}

.filterText:hover {
  transform: scale(1.2);
}

.cleanIcon {
  cursor: pointer;
}

.products {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* padding: 0 10px; */
  /* margin-top: 10px; */
  /* border: 1px solid red; */
}

.product {
  /* border: 1px solid red; */
}

.generation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
  /* min-height: 50px; */

  padding: 10px 0;

  /* cursor: pointer; */

  box-shadow: var(--shadow-2);
  border-radius: var(--radius-2);

  /* border: 1px solid black; */
}

/* .generation:hover {
  box-shadow: var(--shadow-4);
} */

.generationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  padding: 0 10px;

  /* border: 1px solid blue; */
}

.switchGen {
  /* border: 1px solid blue; */
}

.icon {
  font-size: 25px;
  color: darkorange;
}

.noGeneration {
  font-size: 18px;
}

.selectedGeneration {
  font-size: 18px;
}

.bold {
  font-weight: bold;
}

/* .generation > p {
  font-size: 1rem;
} */

.error {
  width: 90vw;
  max-width: 800px;
  margin: 0 auto;
  /* border: 1px solid red; */
  height: 20vh;
  display: grid;
  place-content: center;
  text-align: center;
}

.up {
  position: fixed;
  z-index: 50;
  bottom: 10px;
  right: 40px;

  cursor: pointer;
}

.noProducts {
  text-align: center;
}

.buttons {
  display: flex;
  gap: 10px;
}

.buttons > input {
  flex: 1;
}

@media (min-width: 1000px) {
  .container {
    width: 65vw;
    /* max-width: 1300px; */
    margin: 0 auto;
  }

  .generation {
    /* justify-content: space-between; */
    gap: 35px;
  }
}
