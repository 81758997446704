.container {
  width: min(var(--width-container), var(--max-width-container));

  margin: 0 auto;
  margin-bottom: 10px;

  /* border: 1px solid red; */
}

.content {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 0 10px;

  gap: 20px;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  /* border: 1px solid red; */
}

.address {
  font-size: 18px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  flex: 2;
}

.message {
  padding: 10px 15px;
}

.form > label {
  font-weight: bold;
}
