.page {
  width: 100vw;
  height: 100vh;
  /* display: grid;
  place-content: center; */

  /* background-image: url("./../../../public/login.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */

  /* background-color: white; */
}

.container {
  width: min(95vw, 400px);

  height: 50vh;
  width: 100vw;

  display: grid;
  place-content: center;

  /* border: 1px solid red; */
}

.logo{
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
}

.box {
  /* min-width: 95%; */
  width: min(1000px, 80vw);
  border-radius: var(--radius-2);

  /* border: 1px solid red; */

  background-color: white;

  border: 1px solid gray;

  padding: 20px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.box > img {
  width: fit-content;
  max-width: 500px;
}

/* .container > form {
  background-color: white;
  padding: 20px; 
}*/

.inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.field {
  height: 25px;

  border-radius: var(--radius-3);
  border: 1px solid var(--gray-3);
}

.error {
  text-align: center;
  color: red;
}

.form {
  /* width: 80vw; */
  /* max-width: 350px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  /* border: 1px solid red; */
}

.btns {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.link {
  color: black;
  font-size: 1rem;
}

.link:hover {
  color: darkorange;
  font-weight: 600;
}