.footer {
  width: 100%;
  /* padding: 5px 0px; */
  background-color: black;
  color: white;
  /* position: fixed; */
  /* left: 0; */
  /* bottom: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 50px; */
  /* padding: 0 25px; */

  position: fixed;
  bottom: 0;

  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* width: 100%; */
  /* height: 2.5rem; */

  /* border: 1px solid red; */
}

.logo {
  /* border: 1px solid red; */
  width: 30%;
}

img {
  object-fit: contain;
  width: 100%;
}

.contact {
  /* padding-right: 7px; */
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  color: white;
  flex: 1;
  /* border: 1px solid red; */
  text-align: center;
}

.report {
  /* border: 1px solid red; */
  text-align: center;
  cursor: pointer;
  flex: 1;
  /* border: 1px solid red; */
}

.report p:hover {
  color: var(--mv-yellow);
}

p {
  margin: 0;
  font-size: 0.7rem;
}

textarea {
  width: 100%;
  border-radius: 15px;
}

.buttons {
  /* width: 40%;

  float: right; */

  display: flex;
  justify-content: end;
  gap: 10px;
  margin: 10px 0;
  flex-basis: 1 1 0px;
}

.btn {
  flex: 1 1 0px;
}

.reportModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

textarea {
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}

a {
  color: white;
  font-size: 0.7rem;
}

@media (min-width: 768px) {
  .footer {
    /* width: 100%;
    padding: 5px 0;

    justify-content: center;
    gap: 40px; */
  }

  .logo {
    width: 200px;
  }
}
