.color {
  display: grid;
  place-content: center;

  cursor: pointer;

  border: 1px solid var(--gray-5);
  border-radius: var(--radius-2);

  padding: 5px;
}

.color > p {
  line-height: 0px;
}

.color > main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.selected {
  border: 2px solid aqua;
}

.sample {
  width: 40px;
  height: 40px;

  border: 1px solid black;

  /* background-color: black; */
}