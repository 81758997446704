.breadcrumbs {
    padding: 10px 0;
    display: flex;
    gap: var(--gap-small);
    justify-content: start;
    align-items: center;

    font-weight: 400;
    /* align-items: stretch; */

    /* border: 1px solid red; */
}

.bread {
    font-size: 10px;

    font-weight: 400;

}

.slash {
    font-size: 10px;
    /* border: 1px solid red; */
}

.separtor {
    display: inline;
}

.link {
    color: black;
    font-size: 15px;

    font-weight: 400;

    /* border: 1px solid green; */
}