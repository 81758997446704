.container {
  width: min(95vw, 1100px);
  margin: 0 auto;

  /* border: 1px solid red; */
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.sections {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;

  padding: 10px 0;
}

.abstractDetails {
  display: grid;
  gap: 3px;
  grid-template-columns: auto 4fr;
  justify-items: start;
  row-gap: 5px;
  column-gap: 10px;

  margin-top: 10px;

  padding: 5px;

  font-size: 1.1rem;

  /* border: 1px solid red; */
}

.abstractDetails > p {
  font-size: 1.1rem;
}

.abstractLine {
  font-size: 1.1rem;
  font-weight: 400;
}

.total {
  display: grid;
  gap: 3px;
  grid-template-columns: 4fr auto;
  justify-items: end;
  row-gap: 5px;
  column-gap: 10px;

  margin-top: 10px;

  padding: 5px;

  /* border: 1px solid green; */
}

.total > p {
  font-size: 1.1rem;
}

.footer {
  grid-column-start: 2;
  grid-column-end: 3;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* justify-content: space-between;
  align-items: center; */
  /* padding: 20px 10px; */
  margin: 20px 0;
}

.footerIcon {
  font-size: 1.1rem;
}

.footerLink {
  font-size: 1.1rem;
}

.bold {
  font-weight: 600;
}

.content {
  justify-self: start;
}

.finalPrice {
  /* border: 1px solid red; */
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.price {
  font-size: var(--font-size-2);
}

/* .footer > input, a {
    font-size: 1.1rem;
} */

@media (min-width: 769px) {
  .footer {
    /* background-color: red; */
    flex-direction: row;

    justify-content: space-around;
  }

  .btn {
    /* flex: 1; */

    /* border: 1px solid red; */
  }
}
