@import "https://unpkg.com/open-props";
@import "variables.css";

body {
  /* position: relative;
  min-height: 100vh; */
  background-color: #f8f9fa;
  margin: 0;
  /* background-color: var(--gray-3); */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-sans);
}

input:focus,
textarea:focus {
  outline: none;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 4.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  /* color: black; */
}

.container {
  display: grid;
  grid-template-columns: 10px 1fr 10px;
  row-gap: 10px;

  position: relative;
  min-height: 100vh;
}

.responsive-btn {

  width: min(85vw, 400px);

  border: 1px solid red;
}

.btn-active {
  background-color: darkorange;
  color: white;
  font-weight: 800;
  padding: 12px 19px;
  padding-bottom: 14px;
  border: none;
  border-radius: var(--radius-4);

  cursor: pointer;

  display: grid;
  place-content: center;
}

.btn-secondary {
  background-color: white;
  color: darkorange;
  font-weight: 800;
  padding: 12px 19px;
  padding-bottom: 14px;
  border: none;
  border-radius: var(--radius-4);
  border: 1px solid darkorange;

  cursor: pointer;

  display: grid;
  place-content: center;
}

.btn-cancel {
  background-color: white;
  color: red;
  font-weight: 800;
  padding: 12px 19px;
  padding-bottom: 14px;
  border: none;
  border-radius: var(--radius-4);
  border: 1px solid red;

  cursor: pointer;

  display: grid;
  place-content: center;
}

.btn-disabled {
  background-color: var(--gray-5);
  color: white;
  font-weight: 800;
  padding: 12px 19px;
  padding-bottom: 14px;
  border: none;
  border-radius: var(--radius-4);
  border: 1px solid var(--gray-5);

  cursor: not-allowed;

  display: grid;
  place-content: center;
}

.btn-active:hover,
.btn-secondary:hover,
.btn-cancel:hover {
  box-shadow: var(--shadow-3);
}

.option {
  width: 35px;
  height: 35px;

  padding: 5px;

  cursor: pointer;

  display: grid;
  place-content: center;

  border: solid 2px inherit;

  font-weight: bold;

  /* border: 1px solid red; */
}

.Negro {
  background-color: black;
  color: white;
  border-color: black;
}

.Verde {
  background-color: green;
  border-color: black;
}

.Blanco {
  background-color: white;
  border-color: black;
  color: black;
}

.Gris {
  background: gray;
  border-color: black;
}

.Rojo {
  background-color: red;
  border-color: black;
}

.Azul {
  background-color: blue;
  border-color: black;
  color: white;
}

.Marrón {
  background-color: brown;
  border-color: black;
  color: white;
}

.Naranja {
  background-color: orange;
  border-color: black;
}

.selected {
  border: solid 3px aqua;

  /* background-color: yellow; */
}

@media (min-width: 1400px) {
  .container {
    width: 65vw;
    max-width: 1300px;
    margin: 0 auto;
  }
}
