:root {
    /* Colors */
    --blue: #347FC4;
    --mv-yellow: #FFDF04;
    
    /* Mides */
    --max-width-container: 1100px;
    --width-container: 90vw;

    /* Espais */
    --margin-medium: 10px;

    /* Gap */
    --gap-small: 5px;
    --gap-medium: 10px;
}