.container {
  width: min(95vw, 1100px);
  margin: 0 auto;

  /* border: 1px solid red; */
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.sections {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;

  padding: 10px 0;
}

.abstractDetails {
  display: grid;
  gap: 3px;
  grid-template-columns: auto 4fr;
  grid-auto-rows: 1fr;
  justify-items: start;
  row-gap: 5px;
  column-gap: 10px;

  margin-top: 10px;

  padding: 5px;

  font-size: 1.1rem;

  /* border: 1px solid red; */
}

.abstractDetails > p {
  font-size: 1.1rem;
}

.abstractDetails > input {
  width: min(60%, 350px);
}

.abstractLine {
  font-size: 1.1rem;
  font-weight: 400;
}

.total {
  display: grid;
  gap: 3px;
  grid-template-columns: 4fr auto;
  justify-items: end;
  row-gap: 5px;
  column-gap: 10px;

  margin-top: 10px;

  padding: 5px;

  /* border: 1px solid green; */
}

.total > p {
  font-size: 1.1rem;
}

.client {
  display: flex;
  align-items: center;
  gap: 5px;

  width: min(80%, 300px);
}

.client > input {
  border-radius: var(--radius-2);
  height: 1.7rem;

  width: 100%;
}

.defaultInput {
  border: 2px solid darkorange;
}

.error {
  border: 2px solid red;
}

.footer {
  /* grid-column-start: 2;
  grid-column-end: 3; */

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 10px;
  align-items: center;
  padding: 35px 10px;
  /* margin-bottom: 20px; */

  /* border: 1px solid red; */
}

.footerIcon {
  font-size: 1.1rem;
}

.footerLink {
  font-size: 1.1rem;
}

.bold {
  font-weight: 600;
}

.content {
  justify-self: start;
}

.empty {
  display: grid;
  place-content: center;
  /* background-color: aqua; */
}

.btn {
  margin: 15px auto;

  cursor: pointer;

  display: flex;
  justify-content: center;
}

.btn:hover {
  box-shadow: var(--shadow-3);
}

.btnHolder {
  /* flex: 1 1; */

  display: flex;
  align-items: center;
  gap: 10px;

  /* border: 1px solid red; */
  /* border: 1px solid green; */
}

.points {
  display: flex;
  gap: 5px;

  /* border: 1px solid red; */
}

.points > p {
  font-size: var(--font-size-2);
}

.value {
  display: flex;
  align-items: center;
}

.value > p {
  font-size: var(--font-size-2);
  color: darkorange;
}

.icon {
  color: darkorange;
  font-size: var(--font-size-2);
}

.notesBox {
  width: 100%;
  display: flex;
  justify-content: center;

  /* border: 1px solid green; */
}

.notesBox > textarea {
  /* width: 95%; */
  padding: 15px;

  /* border: 1px solid red; */
}

/* .footer > input, a {
    font-size: 1.1rem;
} */

.finalPrice {
  /* border: 1px solid red; */
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.price {
  font-size: 1.1rem;
  font-size: var(--font-size-2);
}

@media (min-width: 769px) {
  .footer {
    /* background-color: red; */

    /* justify-content: space-around; */
  }
}
