.scroll {
  position: fixed;
  bottom: 10vh;
  right: 10px;
  z-index: 3;
  cursor: pointer;
}

.icon {
  color: darkorange;

  font-size: min(10vw, 35px);
}

.icon:hover {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}
